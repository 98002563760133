<template>
  <v-container class="px-6">
    <div class="d-flex">
      <div class="d-flex align-center py-4 pb-6">
        <span class="text-h2 font-weight-bold">成分管理</span>
      </div>
    </div>
    <template v-if="isLoading">
      <div
        class="mt-4"
        style="display: grid;grid-template-columns: repeat(8,minmax(0,1fr));grid-gap: 8px;width: 100%"
      >
        <v-card
          class="pa-4"
          elevation="0"
          outlined
          @click="ingredientDialog = true"
        >
          <v-responsive
            :aspect-ratio="1"
          >
            <div
              class="d-flex align-center justify-center flex-column"
              style="height: 100%"
            >
              <v-icon large>
                mdi-plus
              </v-icon>
              <div class="text-caption text-center">
                新增成分
              </div>
            </div>
          </v-responsive>
        </v-card>
        <v-card
          v-for="i in ingredientList"
          :key="i.id"
          class="pa-2"
          elevation="0"
          outlined
          @click="editIngredient(i)"
        >
          <v-responsive
            :aspect-ratio="1"
            style="position: relative"
          >
            <div class="d-flex">
              <v-spacer/>
              <div>
                <v-icon @click.stop="deleteIngredient(i)">
                  mdi-delete
                </v-icon>
              </div>
            </div>
            <div
              class="d-flex align-center justify-center mt-4"
            >
              <div class="text-h5 font-weight-bold">
                {{ i.name }}
              </div>
            </div>
          </v-responsive>
        </v-card>
      </div>
    </template>
    <template v-else>
      <div
        class="d-flex align-center justify-center"
        style="height: 400px"
      >
        <v-progress-circular
          indeterminate
          size="64"
        />
      </div>
    </template>
    <v-dialog
      v-model="ingredientDialog"
      max-width="600px"
      persistent
    >
      <v-card class="pa-4">
        <div class=" d-flex">
          <template v-if="editItemID > 0">
            <div class="text-h3 font-weight-bold">
              编辑成分
            </div>
          </template>
          <template v-else>
            <div class="text-h3 font-weight-bold">
              新增成分
            </div>
          </template>
          <v-spacer/>
          <div>
            <v-btn
              icon
              @click="ingredientDialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-form
          ref="name"
          v-model="valid"
          lazy-validation
        >
          <div>
            <div>成分名称</div>
            <v-text-field
              v-model="ingredientName"
              dense
              hide-details
              outlined
            />
          </div>
          <div class="mt-2">
            <div>包含内容</div>
            <v-text-field
              v-model="ingredientContent"
              dense
              hide-details
              outlined
            />
          </div>
        </v-form>
        <div class="d-flex">
          <v-spacer/>
          <template v-if="editItemID > 0">
            <v-btn
              class="primary  mt-4 lighten-2"
              elevation="0"
              style="border-radius: 36px"
              @click="sureEdit"
            >
              保存
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              class="primary  mt-4 lighten-2"
              elevation="0"
              style="border-radius: 36px"
              @click="save"
            >
              确定
            </v-btn>
          </template>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { addIngredient, deleteIngredientList, editIngredient, getIngredientList } from '@/common/Utlis/api'
  import { showSuccessMessage, sureToDelete } from '@/common/utils'

  export default {
    name: 'IngredientSetting',
    data: function () {
      return {
        isLoading: false,
        editItemID: -1,
        ingredientList: null,
        ingredientDialog: false,
        valid: true,
        ingredientName: null,
        ingredientContent: null,
      }
    },
    watch: {
      ingredientDialog (val) {
        if (!val) {
          this.editItemID = -1
          this.ingredientName = ''
          this.ingredientContent = ''
        }
      },
    },
    mounted () {
      this.reload()
    },
    methods: {
      async deleteIngredient (item) {
        await sureToDelete(async () => {
          await deleteIngredientList(item.id)
          showSuccessMessage(this.$t('delete_success'))
          await this.reload()
        })
      },
      async sureEdit () {
        await editIngredient({
          id: this.editItemID,
          name: this.ingredientName,
          content: this.ingredientContent,
        })
        this.ingredientDialog = false
        showSuccessMessage()
        await this.reload()
      },
      editIngredient (item) {
        this.editItemID = item.id
        this.ingredientName = item.name
        this.ingredientContent = item.content
        this.ingredientDialog = true
      },
      async save () {
        await addIngredient({
          name: this.ingredientName,
          content: this.ingredientContent,
        })
        this.ingredientDialog = false
        showSuccessMessage()
        await this.reload()
      },
      async reload () {
        this.isLoading = false
        this.ingredientList = await getIngredientList()
        this.isLoading = true
      },
    },
  }
</script>

<style scoped>

</style>
